import React from "react";
import { graphql, Link } from "gatsby";
import SEO from "components/seo";
import Layout from "components/layout";

interface MdxNode {
  id: string;
  fields: {
    slug: string;
  };
  frontmatter: {
    date: string;
    tags: string[];
    title: string;
    description?: string;
    url: string;
    updated?: string;
  };
  excerpt: string;
}

interface BlogIndexProps {
  path: string;
  data: {
    allMdx: {
      nodes: MdxNode[];
    };
  };
}

const BlogIndex: React.FC<BlogIndexProps> = ({ data }) => {
  const {
    allMdx: { nodes },
  } = data;

  return (
    <>
      <SEO title="Blog posts" />
      <Layout>
        <div className="container dark:prose-dark prose prose-list  mx-auto p-6">
          <h1 className="font-serif">Blog</h1>
          <p className="mt-0">
            Notes on life, productivity and building digital products.
          </p>
          <div className="divide-y divide-light-blue-400 dark:divide-gray-500">
            {nodes.map(node => (
              <BlogNode key={node.id} {...node} />
            ))}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default BlogIndex;

const BlogNode: React.FC<MdxNode> = ({ fields, frontmatter, excerpt }) => (
  <div className="py-10">
    <Link to={fields.slug} className="inline-block pb-3">
      <h2 className="pb-1 font-serif">{frontmatter.title}</h2>
      <div className="text-sm text-gray-500  dark:text-gray-300">
        {frontmatter.date}
      </div>
    </Link>
    <p>{frontmatter.description || excerpt}</p>
  </div>
);

export const query = graphql`
  query AllPublishedPosts {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: { draft: { ne: true } }
        fields: { collection: { eq: "blog" } }
      }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          tags
          title
          updated
          description
        }
        excerpt
      }
    }
  }
`;
